import * as axios from 'axios';
export default class Api {
  service = () => {
    const headers = {
      Accept: 'application/json',
      Authorization: authenticateUser(
        process.env.REACT_APP_CLIENT_USERNAME_BASICAUTH,
        process.env.REACT_APP_CLIENT_PASSWORD_BASICAUTH
      ),
    };
    // if (Store && Store.selloToken) {
    //   headers.Authorization = `Bearer ${Store.selloToken}`;
    // }
    if (localStorage.getItem('accessToken')) {
      headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
    }
    // console.log('URL: ', process.env.REACT_APP_BASE_URL);
    this.client = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      timeout: 100000,
      headers,
    });
    this.client.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );
    this.client.interceptors.response.use(
      function (response) {
        console.log('Success Response: ', response.data);
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        console.log('error', error);
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        // errorHandler(error.response);
        return Promise.reject(error);
      }
    );
    return this.client;
  };
  authService = () => {
    const headers = {
      Accept: 'application/json',
      Authorization: authenticateUser(
        process.env.REACT_APP_CLIENT_USERNAME_BASICAUTH,
        process.env.REACT_APP_CLIENT_PASSWORD_BASICAUTH
      ),
    };
    this.client = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      timeout: 100000,
      headers,
    });
    return this.client;
  };
}
function authenticateUser(user, password) {
  const token = user + ':' + password;
  // Base64 Encoding -> btoa
  const hash = btoa(token);
  return 'Basic ' + hash;
}
const api = new Api();

function getMethod(path, data) {
  const params = new URLSearchParams(data);
  return api
    .service()
    .get(`${path}`, { params })
    .then((user) => user);
}

function postMethod(path, data) {
  return api

    .service()
    .post(`${path}`, data)
    .then((user) => user);
}

function getDatatable(path, data) {
  return api
    .service()
    .post(`${path}`, data)
    .then((user) => user);
}

function updateMethod(path, data, id) {
  return api
    .service()
    .put(`${process.env.REACT_APP_BASE_URL}${path}/${id}`, data)
    .then((user) => user);
}

function deleteMethod(path, id) {
  return api
    .service()
    .delete(`${path}/${id}`)
    .then((user) => user);
}
function postMethodExcel(path, data) {
  return api
    .service()
    .post(`${path}`, data, { responseType: 'arraybuffer' })
    .then((user) => user);
}

export const apiService = {
  getDatatable,
  getMethod,
  postMethod,
  updateMethod,
  deleteMethod,
  postMethodExcel,
};
