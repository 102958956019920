import isString from 'lodash/isString';
import PropTypes from 'prop-types';
// @mui
import { Box, Typography, Link } from '@mui/material';
//
import SvgIconStyle from './SvgIconStyle';

// ----------------------------------------------------------------------





// ----------------------------------------------------------------------

HeaderBreadcrumbs.propTypes = {
  links: PropTypes.array,
  action: PropTypes.node,
  heading: PropTypes.string.isRequired,
  moreLink: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sx: PropTypes.object,
};

export default function HeaderBreadcrumbs({ links, action, heading, subHeading, icon, moreLink = '' || [], sx, ...other }) {

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        component="span"
        style={{ marginTop: '-26px', color: '#1e8b03' }}
        sx={{
          width: 32,
          height: 32,
          display: 'inline-block',
          bgcolor: 'currentColor',
          mask: `url(/icons/${icon}.svg) no-repeat center / contain`,
          WebkitMask: `url(/icons/${icon}.svg) no-repeat center / contain`,
        }} />
      <Box sx={{ flexGrow: 1 }} style={{ paddingLeft: '16px' }}>
        <Typography variant="h4" noWrap style={{ fontSize: '24px', fontStyle: 'normal', fontWeight: '600', color: '#212B36', letterSpacing: '-0.024em' }}>
          {heading}
        </Typography>
        <Typography variant="subtitle" noWrap style={{ fontSize: '13px', fontStyle: 'normal', fontWeight: '500', color: '#637381', letterSpacing: '-0.016em', marginLeft: '-46px' }}>
          {subHeading}
        </Typography>
      </Box>
      {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
    </Box>
  );
}
