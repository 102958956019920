import { useState, useEffect } from 'react';
// @mui
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  IconButton,
} from '@mui/material';
import { useSnackbar } from 'notistack';
// hooks
import { _userList } from '../../_mock';
// components
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import UserListHead from '../deposit/UserListHead';
import UserListToolbar from './UserListToolbar';
import TermsPopUp from './TermsPopUp';

//Api
import { apiService } from 'src/api/api';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: '№', alignRight: false },
  { id: 'description', label: 'Үйлчилгээний нөхцөл', alignRight: false },
  { id: 'function', label: 'Үйлдэл', alignRight: false },
];

export default function TermsOfservice() {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedUser, setSelectedUser] = useState();
  const [tableSetup, setTableSetup] = useState();
  const [userList, setUserList] = useState(_userList);
  const [worker, setWorker] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterModel, setFilterModel] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [doAction, setDoAction] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getUser = async (rowsNumber) => {
    let pagination = {};
    let filterType = {};
    if (filterModel) {
      for (const k in filterModel) {
        filterType[k] = {
          filterType: 'text',
          filter: filterModel[k],
          type: 'contains',
        };
      }
    }
    pagination = {
      code: 'UserAgreementGet',
      filterModel: filterType,
      sortModel: [
        {
          colId: 'id',
          sort: 'desc',
        },
      ],
      startRow: page === 0 ? 0 : Math.max(0, (1 + page) * rowsPerPage - rowsPerPage),
      endRow: rowsNumber
        ? rowsNumber
        : page === 0
        ? rowsPerPage
        : rowsNumber
        ? rowsNumber * (page + 1)
        : rowsPerPage * (page + 1),
    };
    const response = await apiService.postMethod('/ds/dtable', pagination);
    response.data.data.length === 0 ? enqueueSnackbar('Өгөгдөл олдсонгүй', { variant: 'error' }) : console.log();
    setTableSetup(response.data);
    setWorker(response?.data?.data);
    setRefresh(false);
  };

  useEffect(() => {
    getUser();
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    getUser();
  }, [filterModel]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    getUser(event.target.value);
    setPage(0);
  };

  const filterFunction = (data) => {
    setFilterModel(data);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;
  const filteredUsers = applySortFilter(userList, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredUsers.length && Boolean(filterName);

  const test = (row) => {
    setSelectedUser(row);
  };

  const showPopUpMerchant = () => {
    if (confirmModal) {
      return (
        <TermsPopUp
          popUpName="Үйлчилгээний нөхцөл"
          doAction={doAction}
          isEdit={isEdit}
          confirmModal={confirmModal}
          data={worker}
          selectedUser={selectedUser}
          popAction={(check) => popAction(check)}
          isActive={true}
          refreshTable={() => getUser()}
        />
      );
    }
  };

  const popAction = (data) => {
    setConfirmModal(false);
    if (data) {
      setWorker();
    }
  };

  const popUpHandler = (value, number) => {
    setConfirmModal(true);
    setIsEdit(value);
    setDoAction(number);
    if (number === 1) {
      setSelectedUser({});
    }
  };

  return (
    <>
      <Page title="Үйлчилгээний нөхцөл">
        <HeaderBreadcrumbs heading="Үйлчилгээний нөхцөл" icon="personalcard" />
        <Card>
          <UserListToolbar filterName={filterName} onFilterName={filterFunction} refresh={refresh} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                <TableBody style={{ tableLayout: 'fixed', whiteSpace: 'nowrap' }}>
                  {worker?.map((row, index) => {
                    const { id, description } = row;
                    return (
                      <TableRow
                        hover
                        key={index}
                        tabIndex={-1}
                        role="checkbox"
                        style={{ tableLayout: 'fixed', whiteSpace: 'nowrap' }}
                      >
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell align="left">{description}</TableCell>
                        <TableCell align="left" style={{ display: 'flex' }}>
                          <IconButton
                            style={{ color: 'blue' }}
                            onClick={() => {
                              test(row);
                              popUpHandler(false, 2);
                            }}
                          >
                            <Iconify icon={'akar-icons:edit'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={tableSetup?.meta?.total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Page>
      {showPopUpMerchant()}
    </>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((_user) => _user.givenName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
