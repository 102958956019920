import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import GuestGuard from './guards/GuestGuard';
import AuthGuard from './guards/AuthGuard';
// components
import LoadingScreen from './components/LoadingScreen';
import TermsOfservice from './pages/termsOfService/TermsOfService';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const menuAdmin = [
    //Dan
    { path: '/danwebhook', element: <DanHook /> },
    { path: '/danFailedPage', element: <DanFailedPage /> },
    { path: '/danSuccessPage', element: <DanSuccessPage /> },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/user/userlist" replace />, index: true },

        // UserList
        { path: '/dashboard/user/admin/adminlist', element: <AdminList /> },
        { path: '/dashboard/user/userlist', element: <UserList /> },
        { path: '/dashboard/user/userinfo/profile', element: <UserProfile /> },
        { path: '/dashboard/user/userinfo/profile/:id', element: <UserProfile /> },
        //LoanList
        { path: '/dashboard/loan/loanlist', element: <LoanList /> },
        //Ebarimt
        { path: '/dashboard/ebarimt', element: <Ebarimt /> },
        //Account
        { path: '/dashboard/account/confirmedaccountlist', element: <ConfirmedAccountList /> },
        { path: '/dashboard/account/unconfirmedaccountlist', element: <UnconfirmedAccountList /> },
        //Config
        { path: '/dashboard/config', element: <Config /> },
        //Product choose
        { path: '/dashboard/merchant/product', element: <Product /> },
        //AdminMerchant
        { path: '/dashboard/adminMerchant', element: <AdminMerchant /> },
        { path: '/dashboard/merchantReport', element: <MerchantReport /> },
        { path: '/dashboard/adminMerchant/branchList/:id', element: <BranchList /> },
        { path: '/dashboard/adminMerchant/managerlist/:id', element: <ManagerList /> },
        { path: '/dashboard/category', element: <Category /> },
        //MerchantLoan
        { path: '/dashboard/merchantLoan', element: <MerchantLoan /> },
        //Merchant
        { path: '/dashboard/merchant/merchant', element: <Merchant /> },
        { path: '/dashboard/merchantProduct', element: <MerchantProduct /> },
        //Branch
        { path: 'dashboard/branch', element: <MerchantBranch /> },
        //Agent
        { path: 'dashboard/merchant/agent', element: <Agent /> },
        //Dashboard
        { path: 'dashboard/dashboard', element: <Dashboard /> },
        //Report
        { path: 'dashboard/report/briefLoanReport', element: <BriefLoanReport /> },
        { path: 'dashboard/report/detailedLoanReport', element: <DetailedLoanReport /> },
        { path: 'dashboard/report/loanByTermInterval', element: <LoanByTermInterval /> },
        { path: 'dashboard/report/loanClosed', element: <LoanClosed /> },
        { path: 'dashboard/report/loanoverdue', element: <LoanOverdue /> },
        { path: 'dashboard/report/loanPayment', element: <LoanPayment /> },
        //Transaction
        { path: 'dashboard/transaction', element: <TransactionList /> },
        //LoanInfo
        { path: 'dashboard/loanInfo', element: <LoanInfo /> },
        //UnconfirmedTransaction
        { path: 'dashboard/unconfirmedTransaction', element: <UnconfirmedTransaction /> },
        //Credit
        { path: 'dashboard/credit', element: <Credit /> },
        //Terms of service
        { path: 'dashboard/terms', element: <TermsOfservice /> },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ];

  return useRoutes(menuAdmin);
}
// Dashboard
const Login = Loadable(lazy(() => import('./pages/auth/Login')));
const NotFound = Loadable(lazy(() => import('./pages/Page404')));

//Userlist
const AdminList = Loadable(lazy(() => import('./pages/user/admin/AdminList')));
const UserList = Loadable(lazy(() => import('./pages/user/UserList')));
const UserProfile = Loadable(lazy(() => import('./pages/customer/profile/Profile')));
//LoanList
const LoanList = Loadable(lazy(() => import('./pages/loan/LoanList')));

//Account
const ConfirmedAccountList = Loadable(lazy(() => import('./pages/account/ConfirmedAccountList')));
const UnconfirmedAccountList = Loadable(lazy(() => import('./pages/account/UnconfirmedAccountList')));
//Config
const Config = Loadable(lazy(() => import('./pages/config/Config')));
//Product
const Product = Loadable(lazy(() => import('./pages/product/Product')));
//Merchant
const AdminMerchant = Loadable(lazy(() => import('./pages/adminMerchant/AdminMerchant')));
const BranchList = Loadable(lazy(() => import('./pages/adminMerchant/BranchList')));
const ManagerList = Loadable(lazy(() => import('./pages/adminMerchant/ManagerList')));
const MerchantReport = Loadable(lazy(() => import('./pages/merchantReport/MerchantReport')));
const Category = Loadable(lazy(() => import('./pages/category/Category')));

const Merchant = Loadable(lazy(() => import('./pages/merchant/Merchant')));
const MerchantProduct = Loadable(lazy(() => import('./pages/merchantProduct/MerchantProduct')));
//MerchantAdmin
const Agent = Loadable(lazy(() => import('./pages/agent/Agent')));
const MerchantLoan = Loadable(lazy(() => import('./pages/merchantLoan/MerchantLoan')));
//Branch
const MerchantBranch = Loadable(lazy(() => import('./pages/branch/Branch')));
//Dan
const DanHook = Loadable(lazy(() => import('./danwebhook/WebHook')));
const DanSuccessPage = Loadable(lazy(() => import('./danwebhook/DanSuccessPage')));
const DanFailedPage = Loadable(lazy(() => import('./danwebhook/DanFailedPage')));
//Dashboard
const Dashboard = Loadable(lazy(() => import('./pages/dashboard/Dashboard')));
//Report
const BriefLoanReport = Loadable(lazy(() => import('./pages/report/briefLoanReport/BriefLoanReport')));
const DetailedLoanReport = Loadable(lazy(() => import('./pages/report/detailedLoanReport/DetailedLoanReport')));
const LoanByTermInterval = Loadable(lazy(() => import('./pages/report/loanByTermInterval/LoanByTermInterval')));
const LoanClosed = Loadable(lazy(() => import('./pages/report/loanClosed/LoanClosed')));
const LoanOverdue = Loadable(lazy(() => import('./pages/report/loanOverDue/LoanOverdue')));
const LoanPayment = Loadable(lazy(() => import('./pages/report/loanPayment/LoanPayment')));
//Transaction
const TransactionList = Loadable(lazy(() => import('./pages/transaction/TransactionList')));
//LoanInfo
const LoanInfo = Loadable(lazy(() => import('./pages/loanInfo/LoanInfo')));
//Uncofirmed Transaction
const UnconfirmedTransaction = Loadable(lazy(() => import('./pages/unconfirmedTransaction/UnconfirmedTransaction')));
//Credit
const Credit = Loadable(lazy(() => import('./pages/credit/Credit')));
//Terms of Service
const TermsOfService = Loadable(lazy(() => import('./pages/termsOfService/TermsOfService')));
//Ebarimt
const Ebarimt = Loadable(lazy(() => import('./pages/ebarimt/Ebarimt')));
