// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  postregistration: getIcon('user-square'),
  decision: getIcon('task-square'),
  postnotes: getIcon('document'),
  auxiliary: getIcon('personalcard'),
  violation: getIcon('frame'),
  orders: getIcon('document-text'),
  loan: getIcon('square_foot'),
};

export const sidebarConfigMerchant = (roleId) => {
  const menus = [
    {
      subheader: '',
      items: [
        {
          title: 'Хянах самбар',
          path: '/dashboard/dashboard',
          icon: ICONS.postregistration,
          access: ['ROLE_ADMIN'],
        },
        {
          title: 'Хэрэглэгчийн удирдлага',
          path: '/dashboard/user/userlist',
          icon: ICONS.postregistration,
          // children: [
          //   { title: 'Ажилтан', path: '/dashboard/user/admin/adminlist' },
          //   { title: 'Харилцагч', path: '/dashboard/user/userlist' },
          // ],
          access: ['ROLE_ADMIN'],
        },
        {
          title: 'Тайлан',
          path: '/dashboard/report/detailedReport',
          icon: ICONS.decision,
          children: [
            {
              title: 'ЗЭЭЛИЙН ДАНСНЫ ҮЛДЭГДЛИЙН ХУРААНГУЙ',
              path: '/dashboard/report/briefLoanReport',
            },
            {
              title: 'ЗЭЭЛИЙН ДАНСНЫ ҮЛДЭГДЛИЙН ДЭЛГЭРЭНГҮЙ',
              path: '/dashboard/report/detailedLoanReport',
            },
            { title: 'ОЛГОСОН ЗЭЭЛИЙН ТАЙЛАН', path: 'dashboard/report/loanByTermInterval' },
            {
              title: 'ХААГДСАН ЗЭЭЛИЙН МЭДЭЭЛЭЛ',
              path: 'dashboard/report/loanClosed',
            },
            {
              title: 'ХУГАЦАА ХЭТЭРСЭН ЗЭЭЛИЙН ТАЙЛАН',
              path: 'dashboard/report/loanOverdue',
            },
            {
              title: 'ЗЭЭЛИЙН ЭРГЭН ТӨЛӨЛТИЙН МЭДЭЭ',
              path: 'dashboard/report/loanPayment',
            },
          ],
          access: ['ROLE_ADMIN'],
        },
        {
          title: ' Мерчант удирдлага',
          path: '/dashboard/adminMerchant',
          icon: ICONS.decision,
          access: ['ROLE_ADMIN'],
        },
        {
          title: ' Мерчантын тайлан',
          path: '/dashboard/merchantReport',
          icon: ICONS.decision,
          access: ['ROLE_ADMIN'],
        },
        {
          title: ' Мерчантын aнгилал',
          path: '/dashboard/category',
          icon: ICONS.decision,
          access: ['ROLE_ADMIN'],
        },
        {
          title: 'Config',
          path: '/dashboard/config',
          icon: ICONS.decision,
          access: ['ROLE_ADMIN'],
        },
        {
          title: 'Систем хэрэглэгч',
          path: '/dashboard/user/admin/adminlist',
          icon: ICONS.decision,
          access: ['ROLE_ADMIN'],
        },
        {
          title: 'Танигдаагүй гүйлгээ',
          path: '/dashboard/unconfirmedTransaction',
          icon: ICONS.decision,
          access: ['ROLE_ADMIN'],
        },
        {
          title: 'Зарлага',
          path: '/dashboard/credit',
          icon: ICONS.decision,
          access: ['ROLE_ADMIN'],
        },
        {
          title: 'ЗМС',
          path: '/dashboard/loanInfo',
          icon: ICONS.decision,
          access: ['ROLE_ADMIN'],
        },
        // {
        //   title: 'Хэрэглээний зээлийн хүсэлт',
        //   path: '/dashboard/loan/loanlist',
        //   icon: ICONS.decision,
        //   access: ['ROLE_ADMIN'],
        // },
        {
          title: 'Үйлчилгээний нөхцөл',
          path: '/dashboard/terms',
          icon: ICONS.decision,
          access: ['ROLE_ADMIN'],
        },
        {
          title: 'E-barimt',
          path: '/dashboard/ebarimt',
          icon: ICONS.decision,
          access: ['ROLE_ADMIN'],
        },

        // {
        //   title: 'Данс',
        //   path: '/dashboard/postnotes/notesofficersserved',
        //   icon: ICONS.decision,
        //   children: [
        //     { title: 'Баталгаажсан', path: '/dashboard/account/confirmedaccountlist' },
        //     { title: 'Баталгаажаагүй', path: 'dashboard/account/unconfirmedaccountlist' },
        //   ],
        //   access: ['ROLE_ADMIN'],
        // },

        // {
        //   title: 'Байгууллагын нэр, барааны төрөл сонгох',
        //   path: '/dashboard/merchant/product',
        //   icon: ICONS.decision,
        //   access: ['ROLE_ADMIN'],
        // },

        // {
        //   title: 'Худалдан авалтын зээл',
        //   path: '/dashboard/merchantLoan',
        //   icon: ICONS.decision,
        //   access: ['ROLE_ADMIN'],
        // },
        // {
        //   title: 'Гүйлгээний мэдээлэл',
        //   path: '/dashboard/transaction',
        //   icon: ICONS.decision,
        //   access: ['ROLE_ADMIN'],
        // },
        {
          title: 'Merchant',
          path: '/dashboard/merchant/merchant',
          icon: ICONS.decision,
          access: ['ROLE_AGENT'],
        },
        {
          title: 'Салбар',
          path: 'dashboard/branch',
          icon: ICONS.decision,
          access: ['ROLE_AGENT'],
        },
        {
          title: 'Ажилтан/(Agent)',
          path: '/dashboard/merchant/agent',
          icon: ICONS.decision,
          access: ['ROLE_AGENT'],
        },
        {
          title: 'Бараа',
          path: '/dashboard/merchantProduct',
          icon: ICONS.decision,
          access: ['ROLE_AGENT'],
        },
      ],
    },
  ];

  menus[0].items = menus[0].items.filter((value) => value.access.includes(roleId));

  return menus;
};

export const menuConfig = {
  sidebarConfigMerchant,
};
