import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui
import {
  Toolbar,
  Typography,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
// components
import Iconify from 'src/components/Iconify';
import InputStyle from 'src/components/InputStyle';

RegistrationAuxiliaryForceMembersListToolbar.propTypes = {
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function RegistrationAuxiliaryForceMembersListToolbar({ filterName, refresh, onFilterName }) {
  const [filterModel, setFilterModel] = useState({});
  useEffect(() => {
    refresh ? setFilterModel({}) : console.log();
  }, [refresh]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // Send Axios request here
      if (filterModel) {
        onFilterName(filterModel);
      }
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [filterModel]);
  return (
    <div style={{ margin: '15px', marginTop: '40px' }}>
      <Grid>
        <Accordion style={{ display: 'block', backgroundColor: '#F4F6F8' }}>
          <AccordionSummary expandIcon={<Iconify icon={'eva:arrow-ios-downward-fill'} width={20} height={20} />}>
            <Typography variant="subtitle1">Хайлтын шүүлтүүр</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={3}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                <FormControl>
                  <InputStyle
                    label="Үйлчилгээний нөхцөлөөр хайх"
                    // stretchStart={240}
                    value={filterModel['description'] ? filterModel['description'] : ''}
                    size="small"
                    fullWidth
                    onChange={(event) => setFilterModel({ ...filterModel, description: event.target.value })}
                    placeholder="Хайх"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}></Stack>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </div>
  );
}
