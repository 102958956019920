import { useEffect, useState } from 'react';

// @mui
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader } from '@mui/material';
//
import { NavListRoot } from './NavList';

import jwt_decode from 'jwt-decode';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

NavSection.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, isCollapse = false, ...other }) {
  const [role, setRole] = useState(null);
  useEffect(() => {
    const decodedToken = jwt_decode(window.localStorage.getItem('accessToken'));

    setRole(decodedToken.authorities[0]);
  }, []);

  return (
    <Box {...other}>
      {role &&
        navConfig(role).map((group) => (
          <List key={group.subheader} disablePadding sx={{ px: 2 }}>
            <ListSubheaderStyle
              sx={{
                ...(isCollapse && {
                  opacity: 0,
                }),
              }}
            >
              {group.subheader}
            </ListSubheaderStyle>

            {group.items.map((list) => (
              <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
            ))}
          </List>
        ))}
    </Box>
  );
}
