import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { FormikProvider, Form, useFormik } from 'formik';
// routes
import { apiService } from 'src/api/api';
//TextEditor
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function TermsPopUp({
  popUpName,
  doAction,
  confirmModal,
  data,
  isEdit,
  popAction,
  selectedUser,
  refreshTable,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [modalShow, setModalShow] = useState(false);
  const [value, setValue] = useState(selectedUser.agreement);
  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {},
    onSubmit: async (values, { setSubmitting, resetForm, setErrors }) => {
      try {
        // await new Promise((resolve) => setTimeout(resolve, 500));
        console.log('here we are-->', selectedUser);
        const bodyParams = {
          code: 'UserAgreement',
          data: [{ id: selectedUser.id, agreement: value }],
        };
        const response = await apiService.postMethod('ds/crud/exec', bodyParams).catch((error) => {
          enqueueSnackbar(error.response.data.message, { variant: 'error' });
        });
        resetForm();
        setSubmitting(false);
        enqueueSnackbar(isEdit ? 'Create success!' : 'Амжилттай нэмлээ!');
        popAction(false);
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setErrors(error.message);
      }
      refreshTable();
    },
  });

  const { isSubmitting, handleSubmit } = formik;
  const handleClose = async (reason) => {
    popAction(false);
  };
  const closeModal = () => {
    setModalShow(false);
    popAction(false);
  };
  return (
    <div>
      <Dialog open={confirmModal} onClose={closeModal} sx={{ p: 5 }} fullWidth>
        <DialogTitle>{popUpName}</DialogTitle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <ReactQuill theme="snow" value={value} onChange={setValue} />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleClose()} color="inherit">
                {'Хаах'}
              </Button>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {doAction === 1 ? 'Нэмэх' : doAction === 2 ? 'Хадгалах' : 'Устгах'}
              </LoadingButton>
            </DialogActions>
          </Form>
        </FormikProvider>
        {/* <FormProvider methods={methods} onSubmit={handleSubmit}>
          <LabelStyle>Description</LabelStyle>
          <RHFEditor simple name="description" />
        </FormProvider> */}
      </Dialog>
    </div>
  );
}
